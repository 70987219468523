#cursos{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    color: rgb(58, 58, 58);
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.img-curso1{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 65%;
}

#orç{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    color: #DBB33C;
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.7;
}

h3{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    line-height: 4.7;
}



.text-esp{
    font-family: 'Encode Sans', sans-serif;
    /* font-weight: bold; */
    font-size: 25px;
    text-align: center;
    line-height: 1.55;
    margin-left: 200px;
    margin-right: 200px;
}

@media screen and (max-width: 500px) {
    .text-esp {
      position: relative;
      font-size: 23px;
      margin-left: 30px;
      margin-right: 30px;  
    }
    .img-curso1{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        width: 90%;
    }
}