.sessao-despa{
    background: url(despachante.jpg) no-repeat left;
    background-size: 55%;
    background-color: #fdfdfd;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow-x: hidden;
    padding: 100px 0;
    
}

.container-dentro{
    width: 50%;
    float: right;
    background-color: rgb(58, 58, 58);
    padding: 43px;
}

.container-dentro h1{
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 900;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande','Lucida Sans', Arial, sans-serif;
}

.texto{
    font-size: 20px;
    color: #ffffff;
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 40px;
    font-family: 'Encode Sans', sans-serif;
}

.texto1{
    font-size: 20px;
    color: #DBB33C;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: justify;
    font-family: 'Encode Sans', sans-serif;
}

.btndespa{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:1200px){
    .container-dentro{
        padding: 27px;
    }
}

@media screen and (max-width:1000px){
    .sessao-despa{
        background-size: 100%;
        padding: 100px 0px;
        
    }
    .container-dentro{
        width: 100%;
        background-color:rgba(58, 58, 58, 0.924);
    }
}

@media screen and (max-width:500px){
    .sessao-despa{
        background-color:rgba(58, 58, 58);
        background: url(despachante.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow-x: hidden;
        padding: 100px 0;
        
    }
    .container-dentro{
        background-color:rgba(58, 58, 58);
        margin-top: 35%;
    }
}

@media screen and (max-width:375px){
    .sessao-despa{
        width: 100%;
        background-color:rgba(58, 58, 58);
        background: url(despachante.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        max-width: 92%;
        margin-bottom: 40px;
        overflow-x: hidden;
        padding: 100px 0;
        
    }
    .container-dentro{
        background-color:rgba(58, 58, 58);
        margin-top: 35%;
    }
}

