.navbar {
  background: linear-gradient(328deg, rgb(58, 58, 58) 0%, rgb(8, 8, 8) 100%);
  min-height: 100px;
  max-height: 100px;
  display: flex;
  justify-content: space-AROUND;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: inherit;
    max-height: inherit;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    align-self: center;
    margin-left: 20px;
    max-height: inherit;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .logo{
    max-width: 150px;
    max-height: 150px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1820px) {
    .logo{
        margin-left: -73px;
        max-width: 100px;
        max-height: inherit;
    }  
  }

  @media only screen and (max-width: 960px) {
    .logo{
        margin-left: -6px;
        max-width: 100px;
        max-height: inherit;
    }  
  }

  @media only screen and (max-width: 375px) {
    .logo{
        margin-left: -6px;
        max-width: 100px;
        max-height: inherit;
    }

  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 27px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    text-decoration: none;
    margin-right: 2rem;
  }

  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #ffffff;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #dbb33c;
    transition: all 0.2s ease-out;
    color: white;
    text-decoration: none;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90.1vh;
      position: absolute;
      top: 95px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: linear-gradient(1deg, #0D0D0C 0%, #3a3a3a 100% );
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      scroll-behavior: auto;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-container{
      width: 100vw;
    }

    .menu-icon {
      display: block;
/*       position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem; */
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

      .menu-icon {
        display: block;
/*         position: absolute;
        top: 8px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem; */
        cursor: pointer;
      }
    }
    
    @media screen and (max-width:360px){

      .nav-menu.active {
        background: linear-gradient(1deg, #0D0D0C 0%, #3a3a3a 100% );
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        scroll-behavior: auto;
      }

      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
      
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90.1vh;
        position: absolute;
        top: 95px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
      
    }
