
  .hero-container {
      background: url('./hero.jpg') center center/cover no-repeat;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
      overflow-x: hidden;
    }
  
    .sobrep{
      font-weight: bolder;
      line-height: 1.5;
    }

    @import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@700&display=swap');
    

  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -10px;
    font-family: 'Libre Baskerville', serif;
    font-weight: bold;
  }
  
  .hero-container > p {
    margin-top: 28px;
    color: #fff;
    font-size: 32px;
    font-family: 'Encode Sans', sans-serif;
  }
  
  
  .hero-btns {
    margin-top: 60px;
    margin-bottom: 300px;
  }
  
  .hero-btns .btn {
    margin: 6px;
    font-size: 29px;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 375px) {
    .hero-btns{
        margin-top: 44px;
        margin-bottom: 429px;
    }
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 43px;
      margin-top: 45px;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 26px;
      color: #fff;
      text-align: center;
      font-size: 21px;
      font-family: 'Encode Sans', sans-serif;
    }  
  }

  @media only screen and (max-width: 960px) {
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 100px;
      margin-top: -100px;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 28px;
      color: #fff;
      text-align: center;
      font-size: 29px;
      font-family: 'Encode Sans', sans-serif;
    }  
  }

  @media only screen and (max-width: 500px) {
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 56px;
      margin-top: 70px;
      font-family: 'Libre Baskerville', serif;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 43px;
      color: #fff;
      text-align: center;
      font-size: 21px;
      font-family: 'Encode Sans', sans-serif;
    }  
    .hero-btns {
      margin-top: 30px;
      margin-bottom: 300px;
    }
  }

  @media only screen and (max-width: 500px) {
    .hero-container > h1{
      font-size: 49px;
    }
  
  @media only screen and (max-width: 500px) {
    .hero-container > h1{
      font-size: 49px;
    }
  }

  @media only screen and (max-width: 360px) {
    .hero-container > h1{
      font-size: 39px;
    }
  }
}