@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@700&display=swap);
.navbar {
  background: linear-gradient(328deg, rgb(58, 58, 58) 0%, rgb(8, 8, 8) 100%);
  min-height: 100px;
  max-height: 100px;
  display: flex;
  justify-content: space-AROUND;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: inherit;
    max-height: inherit;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    align-self: center;
    margin-left: 20px;
    max-height: inherit;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .logo{
    max-width: 150px;
    max-height: 150px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1820px) {
    .logo{
        margin-left: -73px;
        max-width: 100px;
        max-height: inherit;
    }  
  }

  @media only screen and (max-width: 960px) {
    .logo{
        margin-left: -6px;
        max-width: 100px;
        max-height: inherit;
    }  
  }

  @media only screen and (max-width: 375px) {
    .logo{
        margin-left: -6px;
        max-width: 100px;
        max-height: inherit;
    }

  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 27px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    text-decoration: none;
    margin-right: 2rem;
  }

  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #ffffff;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #dbb33c;
    transition: all 0.2s ease-out;
    color: white;
    text-decoration: none;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90.1vh;
      position: absolute;
      top: 95px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: linear-gradient(1deg, #0D0D0C 0%, #3a3a3a 100% );
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      scroll-behavior: auto;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-container{
      width: 100vw;
    }

    .menu-icon {
      display: block;
/*       position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem; */
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

      .menu-icon {
        display: block;
/*         position: absolute;
        top: 8px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem; */
        cursor: pointer;
      }
    }
    
    @media screen and (max-width:360px){

      .nav-menu.active {
        background: linear-gradient(1deg, #0D0D0C 0%, #3a3a3a 100% );
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        scroll-behavior: auto;
      }

      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
      
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90.1vh;
        position: absolute;
        top: 95px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
      
    }


  .hero-container {
      background: url(/static/media/hero.29c6e953.jpg) center center/cover no-repeat;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
      overflow-x: hidden;
    }
  
    .sobrep{
      font-weight: bolder;
      line-height: 1.5;
    }
    

  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -10px;
    font-family: 'Libre Baskerville', serif;
    font-weight: bold;
  }
  
  .hero-container > p {
    margin-top: 28px;
    color: #fff;
    font-size: 32px;
    font-family: 'Encode Sans', sans-serif;
  }
  
  
  .hero-btns {
    margin-top: 60px;
    margin-bottom: 300px;
  }
  
  .hero-btns .btn {
    margin: 6px;
    font-size: 29px;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 375px) {
    .hero-btns{
        margin-top: 44px;
        margin-bottom: 429px;
    }
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 43px;
      margin-top: 45px;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 26px;
      color: #fff;
      text-align: center;
      font-size: 21px;
      font-family: 'Encode Sans', sans-serif;
    }  
  }

  @media only screen and (max-width: 960px) {
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 100px;
      margin-top: -100px;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 28px;
      color: #fff;
      text-align: center;
      font-size: 29px;
      font-family: 'Encode Sans', sans-serif;
    }  
  }

  @media only screen and (max-width: 500px) {
    .hero-container > h1 {
      color: #fff;
      text-align: center;
      font-size: 56px;
      margin-top: 70px;
      font-family: 'Libre Baskerville', serif;
      font-weight: bold;
    }
    
    .hero-container > p {
      margin-top: 43px;
      color: #fff;
      text-align: center;
      font-size: 21px;
      font-family: 'Encode Sans', sans-serif;
    }  
    .hero-btns {
      margin-top: 30px;
      margin-bottom: 300px;
    }
  }

  @media only screen and (max-width: 500px) {
    .hero-container > h1{
      font-size: 49px;
    }
  
  @media only screen and (max-width: 500px) {
    .hero-container > h1{
      font-size: 49px;
    }
  }

  @media only screen and (max-width: 360px) {
    .hero-container > h1{
      font-size: 39px;
    }
  }
}
:root{
    --primary: #fff;
}

.btn{
    padding: 17px 33px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
}



.btn:focus{
    text-decoration: none;
    outline:none;
    

}

.btn--primary{
    background-color: transparent;
    color: #242424;
    border: 1.5px solid #5D5F54;
    text-decoration: none;
}

.btn--cursos{
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #3a3a3a;
    text-decoration: none;
    color: white;
    margin-bottom: 70px;
}

#btns-voltar{
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #3a3a3a;
    text-decoration: none;
    color: white;
    margin-bottom: 50px;
}


.btn--cursos:focus{
    outline:none;

}

.btn--outline{
    background-color: transparent;
    border: 2px solid #DBB33C;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 4.0rem;
    transition: all 0.3s ease-out;
    text-decoration: none;
}

.btn--outline:focus{
    outline:none;
    text-decoration: none;
    text-transform: none;

}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}
.btn--large{
    padding: 12px 26px;
    font-size: 20px;
    
}

.btn--medium:hover,
.btn--large:hover{
    background: #DBB33C;
    color: #242424;
    text-decoration: none;
    transition: all 0.3s ease-out;
}
a{
    text-decoration: none;
}

.about-section{
    background: url(/static/media/sobre.74676318.jpg) no-repeat left;
    background-size: 55%;
    background-color: #fdfdfd;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;
    padding: 100px 0;
}

.inner-container{
    width: 55%;
    float: right;
    background-color: rgb(58, 58, 58);
    padding: 150px;
}

.inner-container h1{
    margin-bottom: 30px;
    font-size: 45px;
    font-weight: 900;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande','Lucida Sans', Arial, sans-serif;
}

.text{
    font-size: 20px;
    color: #ffffff;
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 40px;
    text-indent: 1em;
    font-family: 'Encode Sans', sans-serif;
}

.text1{
    font-size: 20px;
    color: #DBB33C;
    font-weight: bold;
        
    margin-bottom: 30px;
    text-align: justify;
    font-family: 'Encode Sans', sans-serif;
}

.skills{
    display: flex;
    align-self: auto;
    justify-content: center;
    color: #dbb33c;
    font-size: 20px;
    
}

@media screen and (max-width:1200px){
    .inner-container{
        padding: 20px;
    }
}

@media screen and (max-width:1000px){
    .about-section{
        background: url(/static/media/sobre.74676318.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 100px 0;
    }
    .inner-container{
        width: 100%;
        background-color:rgba(58, 58, 58);
        margin-top: 55%;
    }
}

@media screen and (max-width: 500px){
    .inner-container{
        background-color:rgba(58, 58, 58);
        margin-top: 55%;
    }
    .about-section{
        background: url(/static/media/sobre.74676318.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 100px 0;
    }
}

@media screen and (max-width: 375px){
    .inner-container{
        background-color:rgba(58, 58, 58);
        margin-top: 55%;
    }
    .about-section{
        background: url(/static/media/sobre.74676318.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 100px 0;
    }
}


.sessao-despa{
    background: url(/static/media/despachante.fc6ec2a6.jpg) no-repeat left;
    background-size: 55%;
    background-color: #fdfdfd;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow-x: hidden;
    padding: 100px 0;
    
}

.container-dentro{
    width: 50%;
    float: right;
    background-color: rgb(58, 58, 58);
    padding: 43px;
}

.container-dentro h1{
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 900;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande','Lucida Sans', Arial, sans-serif;
}

.texto{
    font-size: 20px;
    color: #ffffff;
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 40px;
    font-family: 'Encode Sans', sans-serif;
}

.texto1{
    font-size: 20px;
    color: #DBB33C;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: justify;
    font-family: 'Encode Sans', sans-serif;
}

.btndespa{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:1200px){
    .container-dentro{
        padding: 27px;
    }
}

@media screen and (max-width:1000px){
    .sessao-despa{
        background-size: 100%;
        padding: 100px 0px;
        
    }
    .container-dentro{
        width: 100%;
        background-color:rgba(58, 58, 58, 0.924);
    }
}

@media screen and (max-width:500px){
    .sessao-despa{
        background-color:rgba(58, 58, 58);
        background: url(/static/media/despachante.fc6ec2a6.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow-x: hidden;
        padding: 100px 0;
        
    }
    .container-dentro{
        background-color:rgba(58, 58, 58);
        margin-top: 35%;
    }
}

@media screen and (max-width:375px){
    .sessao-despa{
        width: 100%;
        background-color:rgba(58, 58, 58);
        background: url(/static/media/despachante.fc6ec2a6.jpg) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        max-width: 92%;
        margin-bottom: 40px;
        overflow-x: hidden;
        padding: 100px 0;
        
    }
    .container-dentro{
        background-color:rgba(58, 58, 58);
        margin-top: 35%;
    }
}




.curso-container{
    margin-top: 50px;
    margin-bottom: 181px;
    display: flex;
    justify-content: space-evenly;
    
}


.card-button{
    display: flex;
    justify-content: center;
    align-items: center;
}


.button-curso{
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoom{
    transition: transform .2s; 
}

.footer-card {
    padding-top: 1rem;
}

.zoom:hover {
    transform: scale(1.1); 
  }

.texto-solo{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
}

.title-solo{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 25px;
}

.card-solo{
    width: 22rem;
    margin-bottom: 40px;
}



@media only screen and (max-width: 375px){
    .curso-container{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }


}
@media only screen and (max-width: 960px){
    .curso-container{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }
    .card-solo{
        margin-bottom: 40px;
    }
 

}
@media only screen and (max-width: 500px){
    .curso-container{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }
    .card-solo{
        margin-bottom: 40px;
    }
  

}
.footer-main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Encode Sans', sans-serif;
    align-items: center;
    width: 100%;
    height: 112px;
    background-color: #3a3a3a;
}

.footer-text{
    color: rgba(255, 255, 255, 0.171);
    text-align: center;
    padding: 1rem;
}

#fale{
    color: #dbb33c;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
}

#insta-contact{
    display: flex;
    align-self: auto;
    justify-content: center;
    color: #dbb33c;
    font-size: 20px;
    font-family: 'Encode Sans', sans-serif;
    margin-top: 40px;
}

.contact-section{
    background: url(/static/media/teste-imagem.c104457d.png) no-repeat left;
    background-size: 53%;
    background-color: #fdfdfd;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -5px;
    margin-bottom: 40px;
    overflow: hidden;
    padding: 100px 0;
}

.contact-dentro{
    width: 55%;
    float: right;
    background-color: rgb(58, 58, 58);
    padding: 105px;
}

.input-contact{
        
        font-size: 21px;
        font-family: 'Encode Sans', sans-serif;
        border-color: #dbb33c;
        background-color: transparent;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        margin-bottom: 20px;
        box-shadow: 0px 0px 1px rgba(66,66,66,.21);
        text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}

.input-contact:focus {
    outline:none;
}

.label-contact{
    text-align:left;
    font-family: 'Encode Sans', sans-serif;
    color: white;
    font-size:1.4em;
}

.contact-div{
    display: flex;
    flex-direction: column;
}

.contact-div > h3, input, label{
    padding-bottom: 9px;
}


#title-contact{
    color: white;
    font-size: 33px;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
}


.select-contact{
    text-align:left;
    font-family: 'Encode Sans', sans-serif;
    color: white;
    font-size: 19px;
    background: transparent;
    /* border: solid 2px #dbb33c; */
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 2px #dbb33c;
    margin-bottom: 20px;
}

.select-contact:focus {
    outline:none;
}

.opt{
    background: #3a3a3a;
    border: none;
    font-family: 'Encode Sans', sans-serif;
    color: white;
    font-size: 21px;
}

.textarea-contact{
    text-align:left;
    font-family: 'Encode Sans', sans-serif;
    color: white;
    font-size: 19px;
    background: transparent;
    border: solid 2px #dbb33c;
    resize: none;
    margin-bottom: 50px;
    height: 100px;
}

.textarea-contact:focus {
    outline:none;
}

.btn-contact{
    background-color: #DBB33C;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    color: #242424;
    padding: 8px 20px;
    font-size: 4.0rem;
    transition: all 0.3s ease-out;
    text-decoration: none;
    width: 30%;
    font-size: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.btn-contact:hover{
    background: #242424;
    border: 2px solid #DBB33C;
    color: #DBB33C;
    text-decoration: none;
    transition: all 0.3s ease-out;
}

@media screen and (max-width:1200px){
    .contact-dentro{
        padding: 20px;
    }
    .btn-contact{
        width: 100%;
    }
}

@media screen and (max-width:1000px){
    .contact-section{
        background-size: 100%;
    }
    .contact-dentro{
        width: 100%;
    }
    .btn-contact{
        width: 100%;
    }
}

@media screen and (max-width: 500px){
    .contact-dentro{
        background-color:rgba(58, 58, 58);
        margin-top: 55%;
    }
    .contact-section{
        background: url(/static/media/teste-imagem.c104457d.png) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 100px 0;
    }

    .btn-contact{
        width: 100%;
    }
    .label-contact{
        font-size:1.2em;
    }
    .input-contact{
        font-size: 18px;
    }
    .opt{
        font-size: 18px;
    }
}

@media screen and (max-width: 375px){
    .inner-container{
        background-color:rgba(58, 58, 58);
        margin-top: 55%;
    }
    .contact-section{
        background: url(/static/media/teste-imagem.c104457d.png) no-repeat top;
        background-size: 100%;
        background-color: #fdfdfd;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 100px 0;
    }
}

#cursos{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    color: rgb(58, 58, 58);
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.img-curso1{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 65%;
}

#orç{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    color: #DBB33C;
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.7;
}

h3{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    line-height: 4.7;
}



.text-esp{
    font-family: 'Encode Sans', sans-serif;
    /* font-weight: bold; */
    font-size: 25px;
    text-align: center;
    line-height: 1.55;
    margin-left: 200px;
    margin-right: 200px;
}

@media screen and (max-width: 500px) {
    .text-esp {
      position: relative;
      font-size: 23px;
      margin-left: 30px;
      margin-right: 30px;  
    }
    .img-curso1{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        width: 90%;
    }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans', sans-serif;
}

.home,
.sobre,
.serviços,
.despachante
.contato {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.sobre {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.serviços {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.despachante {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contato {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
