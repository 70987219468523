.footer-main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Encode Sans', sans-serif;
    align-items: center;
    width: 100%;
    height: 112px;
    background-color: #3a3a3a;
}

.footer-text{
    color: rgba(255, 255, 255, 0.171);
    text-align: center;
    padding: 1rem;
}

#fale{
    color: #dbb33c;
    font-family: 'Encode Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
}
